const base = {
    get() {
        return {
            url: "https://bishe-end.925i.cn/springbootmt74k/",
            name: "springbootmt74k",
            // 退出到首页链接
            indexUrl: 'https://bishe-end.925i.cn/springbootmt74k/front/index.html'
        };
    },
    getProjectName() {
        return {
            projectName: "旅游网站设计"
        }
    }
}
export default base
